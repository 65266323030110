<template>
  <div>
    <div class="content-header px-0">
      <h1 class="title-I">Licenças da plataforma</h1>
    </div>

    <div class="form-inline col-12 p-0">
      <div class="pl-0 pr-0 col-12 filterInput">
        <b-input-group>
          <b-input-group-prepend is-text
            ><i class="fas fa-search"></i>
          </b-input-group-prepend>
          <input
            v-model="filter"
            type="search"
            class="form-control "
            id="filterInput"
            placeholder="Buscar"
          />
        </b-input-group>
      </div>
      <div
          class="mt-2 col-12 d-flex align-items-center justify-content-center"
        > 
        <router-link
          id="editlicenses"
          v-can="['ADMIN']"
          title="Editar licenças"
          to="/platform/edit"
          class="btn btn-primary add-user col-6 mr-2 align-self-center"
        >
          <span class="d-none d-md-block d-lg-block">Editar licenças</span>
          <span class="d-block d-md-none d-lg-none">
            <i class="fas fa-plus-circle"></i>
          </span>
        </router-link>
        <router-link
          id="editnotifcations"
          v-can="['ADMIN']"
          title="Editar Notificações"
          to="/notification"
          class="btn btn-primary add-user col-6 ml-2 align-self-center"
        >
          <span class="d-none d-md-block d-lg-block">Editar Notificações</span>
          <span class="d-block d-md-none d-lg-none">
            <i class="fas fa-plus-circle"></i>
          </span>
        </router-link>
      </div>
    </div>

    <b-table
      style="background-color: white"
      responsive="sm"
      striped
      borderless
      hover
      class="mt-3 rounded"
      no-sort-reset
      ref="selectableTable"
      sticky-header="610px"
      show-empty
      empty-text="Nenhum registro encontrado"
      empty-filtered-text="Nenhum cliente encontrado para essa busca"
      :busy="load"
      :fields="fields"
      :filter="filter"
      :sort-by.sync="sortBy"
      :items="license_counts"
      :per-page="perPage"
      :current-page="currentPage"
      @filtered="onFiltered"
      @context-changed="changeBsTableState"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <strong>
            <i class="fas fa-sync-alt fa-spin"></i>
          </strong>
        </div>
      </template>

      <template v-slot:table-colgroup>
        <col
          v-for="field in fields"
          :key="field.key"
        />
      </template>
      <template v-slot:cell(actions)="data">
        <router-link
          class="mr-3"
          :to="{
            name: 'group-edit',
            params: { groupName: data.item.name },
          }"
          v-can="['ADMIN']"
          title="Editar Grupo"
          id="platform-editgroup "
        >
        <i class="fas fa-pen iconspace"></i>
        </router-link>
      </template>
    </b-table>

    <div class="row">
      <div v-if="rows" class="col-12 col-md-4 mb-2 pt-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="pagination"
        ></b-pagination>
      </div>
      <div class="col-12 col-md-8">
        <div class="btn-group" role="group">
          <b-dropdown
            variant="primary"
            v-model="perPage"
            :text="`Linhas: ${perPage}`"
          >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 10;
                }
              "
              >10</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 20;
                }
              "
              >20</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = 50;
                }
              "
              >50</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              @click="
                () => {
                  this.perPage = '1000';
                }
              "
              >1000</b-dropdown-item
            >
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../../components/Modal.vue";
import hasRole from "@/utils/hasRole";

export default {
  name: "ManagerPlatform",
  data() {
    return {
      filter: null,
      perPage: 10,
      currentPage: 1,
      rows: 1,
      sortBy: "company_name",
      localCustomer: "",
      fields: [
        { key: "name", sortable: true, label: "Nome do Grupo"},
      ],
      sortedLicenses: [],
      license_counts: [],
    };
  },
  mounted() {
    this.$store.dispatch("user/getGroups");
  },
  methods: {
    sortLicenses(gp) {
      gp.licenses = gp.licenses.sort((a, b) => {
        if (a.role < b.role) {
          return -1;
        }
        if (a.role > b.role) {
          return 1;
        }
        return 0;
      });
    },
    changeBsTableState(table_state) {
      if (this.$options.name) {
        localStorage.setItem(
          `bstable-state-${this.$options.name}`,
          JSON.stringify(table_state)
        );
      }
    },
    onFiltered(groups) {
      this.rows = groups.length;
    },
  },
  watch: {
    groups() {
      if (this.$options.name) {
        const ctx = JSON.parse(
          localStorage.getItem(`bstable-state-${this.$options.name}`)
        );
        if (!ctx){
          this.perPage = 10;
          this.currentPage = 1;
          this.sortBy = "";
          this.rows = this.groups.length;
       } else {
          if (ctx.perPage) {
            this.perPage = ctx.perPage;
          }
          if (ctx.sortBy !== "") {
            this.sortBy = ctx.sortBy;
          }
        }
        // IOT-873: Table indicates page 1, but the content is of the last page visited.
        /*if (ctx.currentPage) {
          this.currentPage = ctx.currentPage;
        }*/
      }
      this.rows = this.groups.length;

      if (this.groups[0]) {
        this.sortLicenses(this.groups[0]);
        this.groups[0].licenses.forEach((license) => {
          this.fields.push({
            key: license.role,
            sortable: true,
            label: license.role,
          });
        });
      }

      if (this.fields[this.fields.length - 1].key !== "actions" && hasRole("ADMIN")) {
        this.fields.push({
          key: "actions",
          sortable: false,
          label: "Ações",
        });
      }

      this.groups.forEach((group) => {
        let license_count = {};
        license_count["name"] = group.name;
        this.sortLicenses(group)
        group.licenses.forEach((license) => {
          license_count[license.role] = license.used_licenses + "/" + license.total_licenses;
        });
        this.license_counts.push(license_count);
      });
    },
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    groups() {
      return this.$store.getters["user/groups"];
    },
  },
  components: {
    Modal,
  },
};
</script>

<style>
.change-my-color {
  fill: green;
}
.iconspace{
  color: gray;
}
@media screen and (max-width: 1040px) {
  .iconspace {
    margin-left: 10px;
    margin-right: 10px;
    color: gray;
  }
}
</style>
