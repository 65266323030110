<template>
  <div
    id="total"
    class="login-body font"
    v-bind:class="{ signinjs: isActive, signupjs: isOk }"
  >
    <div id="container-login" class="container-login">
      <div id="first-content" class="content first-content">
        <div id="first-column" class="first-column"></div>
      </div>

      <div id="second-content" class="content second-content">
        <div id="first-column-second" class="first-column">
          <Logo
            :typeLogo="'logo3'"
            :showIdeal="true"
            :width="300"
            colorText="#fff"
          />
        </div>

        <div id="second-column" class="second-column">
          <h2 id="login-title" class="title title-second">fazer login</h2>

          <form id="login-form" class="form card">
            <label
              id="label-for-pass-show"
              class="label-input pass-show"
              for="email"
            >
              <i id="envelope-icon" class="far fa-envelope icon"></i>

              <input
                id="email"
                type="email"
                required
                v-model="email"
                class="formzinho form-control"
                placeholder="Email"
                style="height: 45px; width: 100%; margin-bottom: 10px"
              />
            </label>

            <div
              id="form-group-div"
              class="form-group pass-show"
              v-show="internalLogin"
            >
              <label id="label-for-pass" class="label-input" for="password">
                <i
                  id="lock-icon"
                  class="fas fa-lock icon"
                  style="padding: 0 11px"
                ></i>

                <input
                  id="password"
                  placeholder="Senha"
                  class="formzinho form-control"
                  required
                  v-model="password"
                  :type="!passShow ? 'password' : 'text'"
                  style="height: 45px; width: 100%"
                />

                <a id="show-pass-eye" @click="showPass" href="/" class="icon2">
                  <i
                    id="eye-icon"
                    v-bind:class="{
                      'far fa-eye': passShow,
                      'far fa-eye-slash': !passShow,
                    }"
                  ></i>
                </a>
              </label>
            </div>

            <div
              id="remember-me-form"
              class="form-check row"
              style="margin-left: 30px"
              v-show="internalLogin"
            >
              <label id="label-for-rme-group" for="">
                <input
                  v-model="rememberMe"
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />

                <label
                  for="exampleCheck1"
                  class="form-check-label"
                  style="margin-left: 10px"
                  id="label-for-rme"
                >
                  Manter conectado
                  <i
                    id="remember-me-i"
                    title="Caso essa opção não esteja selecionada a sessão será encerrada no fechamento da janela."
                    class="fas fa-question-circle"
                  >
                  </i>
                </label>
              </label>
            </div>

            <a
              id="forgot-pass-anchor"
              class="password"
              href="/recover-pass"
              v-show="internalLogin"
            >
              Esqueceu a senha?
            </a>

            <button
              id="login-external"
              class="btn-login btn-login-pass"
              style="color: white; width: 50%"
              @click="!internalLogin ? login_saml() : login()"
              v-show="!internalLogin"
            >
              <i
                class="fa-brands fa-xl fa-microsoft"
                style="padding-right: 10px"
              ></i>
              Microsoft
            </button>

            <h2
              class="password"
              style="margin-bottom: 0px"
              v-show="!internalLogin"
            >
              <b>OU</b>
            </h2>

            <button
              id="login-entrar"
              class="btn-login btn-login-pass"
              v-bind:style="
                !internalLogin
                  ? 'color: #021d3b; width: 50%;background-color:white!important; border:3px solid #021d3b;'
                  : 'color: white; width: 50%'
              "
              @click="login"
            >
              <i
                class="fa-solid fa-xl fa-arrow-right-to-bracket"
                style="padding-right: 10px"
              ></i>
              Entrar
            </button>

            <h2
              class="password"
              style="margin-bottom: 0px"
              v-show="internalLogin"
            >
              <b>OU</b>
            </h2>

            <button
              id="login-voltar"
              class="btn-login btn-login-pass"
              style="
                color: #021d3b;
                width: 50%;
                background-color: #ffa517 !important;
              "
              @click="changeInternal"
              v-show="internalLogin"
            >
              <i
                class="fa-regular fa-2xl fa-circle-left"
                style="padding-right: 10px"
              ></i>
              Voltar
            </button>

            <div id="login-loading-div" v-if="load" class="overlay text-center">
              <i id="login-loading-i" class="fas fa-sync-alt fa-spin"></i>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { encrypt_data, decrypt_data } from "@/utils/Crypto";
import hasRole from "../../utils/hasRole";
import Logo from "@/components/Logo.vue";

export default {
  components: {
    Logo,
  },
  data: function () {
    return {
      passShow: false,
      passkey: false,
      isActive: true,
      isOk: false,
      email: "",
      password: "",
      rememberMe: true,
      internalLogin: false,
    };
  },
  computed: {
    load() {
      return this.$store.getters["load"];
    },
    year: () => {
      const date = new Date();
      return date.getFullYear();
    },
  },
  created() {
    if (localStorage.email != undefined || sessionStorage.email != undefined) {
      if (hasRole("USER")) {
        this.$router.push("/user");
      } else {
        this.$router.push("/customer");
      }
    }
    if (this.$store.getters["saveRememberUser"]) {
      this.email = this.$store.getters["saveUserLogged"];
    }
    if (
      window.location.search.includes("request_id") &&
      localStorage.gank != undefined
    ) {
      let local_email = decrypt_data(localStorage.gank);
      let local_password = window.location.search.split("request_id=")[1];
      localStorage.removeItem("gank");
      localStorage.setItem("rememberMe", true);
      this.$store.dispatch("login/login", {
        email: local_email,
        password: local_password,
      });
    }
  },
  methods: {
    showPass() {
      event.preventDefault();
      this.passShow = !this.passShow;
    },
    showkey() {
      this.passkey = !this.passkey;
    },
    btnSingin() {
      this.isActive = false;
      this.isOk = true;
    },
    btnSingup() {
      this.isActive = true;
      this.isOk = false;
    },
    changeInternal() {
      event.preventDefault();
      this.internalLogin = !this.internalLogin;
    },
    login() {
      event.preventDefault();
      if (!this.internalLogin) {
        this.internalLogin = true;
      } else {
        let form = document.getElementById("login-form");
        if (form.reportValidity()) {
          localStorage.setItem("rememberMe", this.rememberMe);
          this.$store.dispatch("login/login", {
            email: this.email,
            password: this.password,
          });
        }
      }
    },
    login_saml() {
      event.preventDefault();
      if (
        this.email.trim() != "" &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.email.trim()
        )
      ) {
        let redirect = "https://" + window.location.hostname + "/";
        let secret = encrypt_data(this.email);
        localStorage.setItem("gank", secret);
        this.$store.dispatch("login/loginSAML", {
          email: this.email,
          redirect_to: redirect,
        });
      } else if (this.email.trim() != "") {
        window.frontMsg("FRNT_INVALID_FIELD_WARNING", {
          field: "E-mail",
          extra: "E-mail só pode conter letras, números, pontos e traços",
        });
      } else {
        window.frontMsg("FRNT_EMPTY_WARNING", {
          field: "E-mail",
        });
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.card {
  box-shadow: none !important;
}
.font {
  font-family: "Montserrat";
}
.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent;
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.content {
  background-color: #fff;
  border-radius: 5px;
  width: 70vw;
  min-height: 65%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.content::before {
  padding: 30px;
  content: "";
  position: absolute;
  background-color: #021d3b;
  width: 42%;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.title {
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
}
.title-primary {
  color: #fff;
}
.title-second {
  color: black;
}
.description {
  font-size: 16px;
  font-weight: 300;
  line-height: 10px;
}
.description-primary {
  color: #fff;
}
.description-second {
  color: #7f8c8d;
  margin-top: 10px;
}
.btn-login {
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 10px;
  padding: 10px 25px;
  cursor: pointer;
  font-weight: bold;
  width: 150px;
  align-self: center;
  border-width: 0;
  margin-top: 1rem;
  border: none;
}
.btn-login-primary {
  background-color: transparent;
  border: 1px solid #fff;
  transition: background-color 0.5s;
  color: #fff;
}
.btn-login-primary:hover {
  background-color: #ffa517;
  color: #021d3b;
}
.btn-login-second {
  background-color: transparent;
  border: 1px solid black;
  transition: background-color 0.5s;
}
.btn-login-second:hover {
  background-color: #ffa517;
  border: 1px solid white;
  color: white;
}
.first-content {
  display: flex;
}
.first-content .second-column {
  z-index: 11;
}
.first-column {
  text-align: center;
  width: 40%;
  z-index: 10;
}
.second-column {
  width: 60%;
  display: flex;
  margin: 30px;
  flex-direction: column;
  align-items: center;
}
.form {
  display: flex;
  flex-direction: column;
  width: 55%;
}
.formzinho {
  border: none;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 5px 12px;
  width: 100%;
}
.label-input {
  display: flex;
  align-items: center;
  margin: 5px;
  position: relative;
  width: 100%;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ecf0f1 inset !important;
  -webkit-text-fill-color: #000 !important;
}
.icon {
  color: #7f8c8d;
  padding: 0 10px;
  font-size: 20px;
}
.icon2 {
  color: #7f8c8d;
  font-size: 18px;
  padding: 0 12px !important;
}

/* second content*/

.second-content {
  position: absolute;
  display: flex;
}
.second-content .first-column {
  order: 2;
  z-index: -1;
}
.second-content .second-column {
  order: 1;
  z-index: -1;
}
.password {
  color: #34495e;
  font-size: 14px;
  margin: 15px 0;
  text-align: center;
}
.password::first-letter {
  text-transform: capitalize;
}

.signinjs .first-content .first-column {
  z-index: -1;
}

.signinjs .second-content .second-column {
  z-index: 11;
}
.signinjs .second-content .first-column {
  z-index: 13;
}

.signinjs .content::before {
  left: 60%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  animation: slidein 1s; /*MODIFIQUEI DE 3s PARA 1.3s*/

  z-index: 12;
}

.signupjs .content::before {
  animation: slideout 1s; /*MODIFIQUEI DE 3s PARA 1.3s*/

  z-index: 12;
}

.signupjs .second-content .first-column,
.signupjs .second-content .second-column {
  z-index: -1;
}

.signupjs .first-content .second-column {
  z-index: 11;
}

.signupjs .first-content .first-column {
  z-index: 13;
}

/* DESLOCAMENTO CONTEÚDO ATRÁS DO CONTENT:BEFORE*/
.signinjs .first-content .second-column {
  z-index: -1;
  position: relative;
  animation: deslocamentoEsq 1.6s; /*MODIFIQUEI DE 3s PARA 1.3s*/
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.signupjs .second-content .second-column {
  position: relative;
  z-index: -1;
  animation: deslocamentoDir 1.6s; /*MODIFIQUEI DE 3s PARA 1.3s*/
}
.signinjs .first-content .second-column {
  z-index: -1;
  position: relative;
  animation: deslocamentoEsq 1.6s; /*MODIFIQUEI DE 3s PARA 1.3s*/
}

.signupjs .second-content .second-column {
  position: relative;
  z-index: -1;
  animation: deslocamentoDir 1.6s; /*MODIFIQUEI DE 3s PARA 1.3s*/
}

/*ANIMAÇÃOO CSS PARA O CONTEÚDO*/

@keyframes deslocamentoEsq {
  from {
    left: 0;
    opacity: 1;
    z-index: 12;
  }

  25% {
    left: -80px;
    opacity: 0.5;
    /* z-index: 12; NÃO HÁ NECESSIDADE */
  }
  35% {
    left: -90px;
    opacity: 0.5;
    /* z-index: 12; NÃO HÁ NECESSIDADE */
  }

  50% {
    left: -100px;
    opacity: 0.2;
    /* z-index: 12; NÃO HÁ NECESSIDADE */
  }

  to {
    left: -110px;
    opacity: 0;
    z-index: -1;
  }
}

@keyframes deslocamentoDir {
  from {
    left: 0;
    z-index: 12;
  }

  25% {
    left: 80px;
    /* z-index: 12;  NÃO HÁ NECESSIDADE*/
  }
  35% {
    left: 90px;
    opacity: 0.5;
    /* z-index: 12; NÃO HÁ NECESSIDADE */
  }

  50% {
    left: 100px;
    /* z-index: 12; NÃO HÁ NECESSIDADE*/
    /* background-color: yellow;  Exemplo que dei no vídeo*/
  }

  to {
    left: 110px;
    z-index: -1;
  }
}

/*ANIMAÇÃO CSS*/

@keyframes slidein {
  from {
    left: 0;
    width: 40%;
  }

  to {
    left: 60%;
    width: 40%;
  }
}

@keyframes slideout {
  from {
    left: 60%;
    width: 40%;
  }

  25% {
    left: 45%;
    width: 50%;
  }

  50% {
    left: 25%;
    width: 60%;
  }

  75% {
    left: 5%;
    width: 50%;
  }

  to {
    left: 0;
    width: 40%;
  }
}
/*VERSÃO MOBILE*/
@media screen and (max-width: 1040px) {
  .content {
    width: 100%;
    height: 100%;
  }

  .content::before {
    width: 100%;
    height: 40%;
    top: 0;
    border-radius: 0;
  }

  .first-content,
  .second-content {
    flex-direction: column;
    justify-content: space-around;
  }

  .first-column,
  .second-column {
    width: 100%;
  }

  .signinjs .content::before {
    top: 60%;
    left: 0;
    border-radius: 0;
  }

  .form {
    width: 90%;
  }

  /* ANIMAÇÃO MOBILE CSS*/

  @keyframes deslocamentoEsq {
    from {
      top: 0;
      opacity: 1;
      z-index: 12;
    }

    25% {
      top: -80px;
      opacity: 0.5;
      /* z-index: 12; NÃO HÁ NECESSIDADE */
    }

    50% {
      top: -100px;
      opacity: 0.2;
      /* z-index: 12; NÃO HÁ NECESSIDADE */
    }

    to {
      top: -110px;
      opacity: 0;
      z-index: -1;
    }
  }

  @keyframes deslocamentoDir {
    from {
      top: 0;
      z-index: 12;
    }

    25% {
      top: 80px;
      /* z-index: 12;  NÃO HÁ NECESSIDADE*/
    }

    50% {
      top: 100px;
      /* z-index: 12; NÃO HÁ NECESSIDADE*/
      /* background-color: yellow;  Exemplo que dei no vídeo*/
    }

    to {
      top: 110px;
      z-index: -1;
    }
  }

  @keyframes slidein {
    from {
      top: 0;
      height: 40%;
    }

    25% {
      top: 5%;
      height: 50%;
    }

    50% {
      top: 25%;
      height: 60%;
    }

    75% {
      top: 45%;
      height: 50%;
    }

    to {
      top: 60%;
      height: 40%;
    }
  }

  @keyframes slideout {
    from {
      top: 60%;
      height: 40%;
    }

    25% {
      top: 45%;
      height: 50%;
    }

    50% {
      top: 25%;
      height: 60%;
    }

    75% {
      top: 5%;
      height: 50%;
    }

    to {
      top: 0;
      height: 40%;
    }
  }
}

@media screen and (max-width: 740px) {
  .form {
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  .form {
    width: 100%;
  }
}
</style>
