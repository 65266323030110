<template>
  <div id="logo-container" class="flex-column-center">
    <img id="logo-img" :src="imgPath" :width="width" />
    <p
      id="logo-description"
      v-if="showIdeal"
      :style="'color:' + colorText"
      class="logo-description"
    >
      {{ idealDescription }}
    </p>
  </div>
</template>
<script>
export default {
  name: "Logo",

  props: {
    typeLogo: String,
    showIdeal: Boolean,
    width: Number,
    colorText: String,
  },
  computed: {
    idealDescription: () => {
      return "(Seu ideal)";
    },
    imgPath() {
      switch (this.typeLogo) {
        case "logo1":
          return require("@/assets/img/Logo1.png");
          break;
        case "logo2":
          return require("@/assets/img/Logo2.png");
          break;
        case "logo3":
          return require("@/assets/img/Logo3.png");
          break;
        default:
          return "";
          break;
      }
    },
  },
};
</script>

<style>
.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

img {
  margin-bottom: 10px;
}

p {
  margin: 0;
}
</style>
