<template>
  <aside class="main-sidebar sidebar-light-navy elevation-4">
    <!-- Brand Logo -->
    <router-link class="brand-link" to="/customer" id="gotocustomer">
      <Logo :typeLogo="'logo1'" :showIdeal="false" :width="150" />
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->

      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image d-flex align-items-center">
          <i style="font-size: 36px" class="far fa-user"></i>
        </div>
        <div class="info">
          <a href="#" class="d-block">
            <b>{{ !name ? "Carregando..." : name }}</b>
          </a>
          <a @click="logout" id="link-logout" class="text-primary" href="#"
            >Logout</a
          >
        </div>
      </div>

      <!-- Sidebar -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column">
          <li
            class="nav-item"
            v-can="['ADMIN', 'MANAGER', 'EMPTY', 'DASHBOARD', 'SYSADMIN']"
          >
            <router-link id="dashboardsidebar" class="nav-link" to="/dashboard">
              <i class="fas fa-chart-pie nav-icon"></i>

              <p>Dashboards</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-can="[
              'ADMIN',
              'SYSADMIN',
              'MANAGER',
              'DASHBOARD',
              'USER',
              'MOBILE',
              'DEVICE',
              'USRADMIN',
            ]"
          >
            <router-link id="usersidebar" class="nav-link" to="/user">
              <i class="fas fa-user nav-icon"></i>

              <p>Usuários</p>
            </router-link>
          </li>

          <li
            v-can="[
              'ADMIN',
              'SYSADMIN',
              'MANAGER',
              'DASHBOARD',
              'FRONTDESK',
              'USER',
            ]"
            class="nav-item"
          >
            <router-link id="customersidebar" class="nav-link" to="/customer">
              <i class="far fa-id-card nav-icon"></i>
              <p>Clientes</p>
            </router-link>
          </li>
          <li
            class="nav-item"
            v-can="['ADMIN', 'SYSADMIN', 'MANAGER', 'DASHBOARD', 'USER']"
          >
            <router-link id="groupssidebar" class="nav-link" to="/group">
              <i class="fa fa-users nav-icon"></i>
              <p>Grupos</p>
            </router-link>
          </li>

          <li class="nav-item" v-can="['ADMIN']">
            <router-link id="platformsidebar" class="nav-link" to="/platform">
              <i class="fa fa-solid fa-laptop-code nav-icon"></i>
              <p>Plataforma</p>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
import { api } from "../services/api";
import Logo from "@/components/Logo.vue";

export default {
  name: "SideBar",
  components: {
    Logo,
  },
  data: function () {
    return {
      name: "",
      email: "",
      type: "",
    };
  },
  mounted() {
    const email =
      localStorage.getItem("rememberMe") === "true"
        ? localStorage.getItem("email")
        : sessionStorage.getItem("email");

    api.get(`/users/${email}`).then((response) => {
      this.name = response.data.name;
      this.type = response.data.type;
      this.email = response.data.email;
    });
  },
  methods: {
    async logout() {
      event.preventDefault();
      // clear all local storage
      localStorage.setItem("access_token", "");
      localStorage.clear();
      if (this.type == "EXTERNAL") {
        let redirect = "https://" + window.location.hostname + "/";
        this.$store.dispatch("login/logoutSAML", {
          email: this.email,
          redirect_to: redirect,
        });
      } else {
        this.$store.dispatch("login/logout");
      }
    },
    async logoutSAML() {
      event.preventDefault();
      // clear all local storage
      // set access_token to empty to trigger event in other tabs
      localStorage.setItem("access_token", "");
      localStorage.clear();
      this.$store.dispatch("login/logoutSAML");
    },
  },
};
</script>

<style></style>
