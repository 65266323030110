import { render, staticRenderFns } from "./ConfirmReset.vue?vue&type=template&id=2fb7e442&scoped=true"
import script from "./ConfirmReset.vue?vue&type=script&lang=js"
export * from "./ConfirmReset.vue?vue&type=script&lang=js"
import style0 from "./ConfirmReset.vue?vue&type=style&index=0&id=2fb7e442&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fb7e442",
  null
  
)

export default component.exports